<template>
  <vuestic-widget v-if="user" :loading="fetching">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">My Installs
          <span v-if="showInstallLimit" class="col availabe-numbers">
            ( {{ businessAvailabeInstall }} {{ businessAvailabeInstall > 1 ? 'installs' : 'install' }} available out of {{ businessTotalInstall }} )
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="mr-2">
            <b-nav pills>
              <b-nav-item active><router-link :to="{name: 'business.installs.active'}">Active</router-link></b-nav-item>
              <b-nav-item><router-link :to="{name: 'business.installs.archived'}">Archived</router-link></b-nav-item>
            </b-nav>
          </div>
          <button class="btn btn-primary btn-sm float-right" @click="onNewInstall">
            <span>Add Install <i class="fa fa-plus"></i></span>
          </button>
        </div>
      </div>
    </template>

    <datatable v-bind="tableConfig" class="le-datatable" />

    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleDeleteInstall"
      @cancel="closeModalDelete"
      okText="Delete"
      cancelText="Cancel"
      :processing="processing"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Install</span>

      <div>Confirm you want to delete the install for <span v-if="modalDeleteInstall.properties && modalDeleteInstall.properties.website" class="text-danger"><b>{{modalDeleteInstall.properties.website}}</b></span> ?</div>
      <div v-if="modalDeleteInstall.number">
        Please note that the assigned phone number <b class="text-danger">{{modalDeleteInstall.number}}</b> will be released.
      </div>
      <div>The install will be archived for 15 days and then permanently deleted.</div>

      <div v-if="modalDeleteInstall.contact_count" class="row mt-4">
        <div class="col-md-12">
          <div class="text-danger font-weight-bold">You have {{modalDeleteInstall.contact_count}} contact(s) for this phone number, Plesae select an install to transfer contacts to or all contacts will be deleted. If there is no install available create one before deleting this one.</div>
          <br/>
          <multiselect 
            :show-labels="false"
            v-model="transferInstall"
            :options="installOptions"
            :multiple="false"
            :preserve-search="true"
            placeholder="Select Install"
            label="label"
            track-by="label"
          >
          </multiselect>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalCode" :hideDefaultActions="true"
      @cancel="closeModalCode" okText="Copy" cancelText="Close" :closeOnOk="false"
      @ok="copyEmbedCode">
      <span slot="title">Install HTML Code</span>
      <strong>Copy the widget code and install it on Your website</strong>
      <p>Copy the code below and paste it right before the closing <strong class="le-blue">&lt;/body&gt;</strong> tag or <strong class="le-blue">&lt;footer&gt;</strong> of your website code</p>

      <textarea class="code" rows="4" id="embedCode" readonly v-model="embedCode"></textarea>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalRelease" :hideDefaultActions="true"
      @cancel="closeModalRelease" okText="Release" cancelText="Close" :closeOnOk="false"
      :processing="processing" @ok="releaseNumber">
      <span slot="title">Release Number</span>
      <p v-if="selectedInstall">Are you sure you want to release this Install Number <b>{{ selectedInstall.available_number | phone('national') }}</b> ?</p>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalRestrict"
      @cancel="isOpenModalRestrict = false"
      :cancelShown="true"
      :okShown="false"
      cancelText="Close"
      cancelClass="btn btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">No Installs Available</span>
      <div class="d-block text-center">
        <span class="no-install-available-span">You don't have any installs available. If you would like to add a new install you must delete an existing install or upgrade your subscription to add additional installs. Please contact us at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> If you would like to add an additional install.</span>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import ActionsActive from './Actions/ActionsActive'
  import CreateInstall from './Actions/CreateInstall'
  import ColumnNumber from './Columns/Number'
  import ColumnWidgetIsSet from './Columns/WidgetIsSet'
  import components from '../../common/tables/comps'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  export default {
    name: "InstallActive",
    components: {
      CreateInstall,
    },
    created () {
      this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
        this.openModalDelete(row)
      })

      this.tableConfig.xprops.eventbus.$on('openReleaseModal', row => {
        this.openModalRelease(row)
      })

      this.tableConfig.xprops.eventbus.$on('openShowHTMLCodeModal', this.openShowHTMLCodeModal)

      this.$store.dispatch('business/getInstallStats')
        .then(res => {
          this.businessTotalInstall = res.data.total_installs
          this.businessAvailabeInstall = res.data.available_installs
        })
    },
    mounted () {
      $('.disabled-menu').removeClass('disabledTrue');
      this.tableConfig.xprops.eventbus.$on('toggle', this.toggleActive);
      
      this.loadData();
      this.tableConfig.data = this.tableData;
      this.tableConfig.total = this.tableTotal;
    },
    watch: {
      'tableConfig.query': {
        handler: function(newValue, oldValue) {
          this.onQueryChange(newValue)
        },
        deep: true,
      },
      tableData: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.tableConfig.data = newValue;
          }
        },
        deep: true,
      },
      tableTotal: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
            this.tableConfig.total = newValue;
        },
        deep: true,
      }
    },
    data () {
      return {
        selectedInstall: null,
        embedCode: null,
        businessTotalInstall: null,
        isOpenModalCode: false,
        isOpenModalDelete: false,
        isOpenModalRelease: false,
        modalDeleteInstall: {},
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: 'table-bordered',
          tblStyle: 'color: #666',
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            const cols = [
              {
                title: 'ID',
                field: 'id',
                label: 'Install ID',
                sortable: true,
                visible: true,
              },
              {
                title: 'Website',
                sortable: true,
                field: 'website',
                tdComp: 'PropertyValue',
                tdStyle: { fontStyle: 'normal' },
              },
              {
                title: 'Phone Number',
                sortable: false,
                visible: true,
                tdClass: 'center',
                tdComp: ColumnNumber,
              },
              {
                title: 'Contacts',
                sortable: false,
                field: 'contact_count',
                visible: true,
                tdClass: 'center',
              },
              {
                title: 'Set',
                field: 'is_used',
                sortable: false,
                visible: true,
                tdClass: 'center',
                tdComp: ColumnWidgetIsSet,
              },
              {
                title: 'Active',
                field: 'active',
                sortable: false,
                visible: true,
                tdClass: 'center',
                tdComp: "ActiveToggleTd",
              },
              {
                title: '',
                tdComp: ActionsActive,
                visible: 'true',
                tdClass: 'center',
              },
            ]
            return cols
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue(),
          },
        },
        businessAvailabeInstall: 0,
        installOptions: [],
        transferInstall: null,
        isOpenModalRestrict: false,
      }
    },
    methods: {
      onNewInstall() {
        if (this.businessTotalInstall && !this.businessAvailabeInstall)
        {
          this.isOpenModalRestrict = true
        } else {
          this.$router.push({ name: 'business.installs.create' });
        }
      },
      loadData () {
        this.$store.dispatch('install/fetchAllInstalls')
      },
      onQueryChange (query) {
        const page = Math.floor(query.offset / query.limit) + 1
        const param = {
          page,
          perPage: query.limit
        }
        this.$store.commit('install/filterList', param)
      },
      handleDeleteInstall () {
        const id = this.modalDeleteInstall.id
        const data = {
          id,
          transferId: this.transferInstall ? this.transferInstall.id : undefined,
        }
        this.$store.dispatch('install/archiveInstall', data).then((result) => {
          this.closeModalDelete();
          this.businessAvailabeInstall ++;
          this.loadData()
        }).catch(e => {
          console.warn(e)
        })
      },
      openModalDelete (install) {
        this.installOptions = this.tableConfig.data.filter(item => item.id !== install.id)
          .map(item => {
            const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number: '')
            return {
              id: item.id,
              label:( phoneNumber ? phoneNumber.formatNational() + ' : ' : '' ) + item.website
            }
          })

        this.isOpenModalDelete = true
        this.modalDeleteInstall = install
        this.transferInstall = null
      },
      closeModalDelete () {
        this.isOpenModalDelete = false
        this.modalDeleteInstall = {}
      },
      openModalRelease (install) {
        if (!install || !install.available_number) {
          Vue.$toast.open({
            message: 'Twilio number is not assigned',
            type: 'error',
          })
          return
        }
        this.selectedInstall = install
        this.isOpenModalRelease = true
      },
      closeModalRelease () {
        this.isOpenModalRelease = false
        this.selectedInstall = null
      },
      releaseNumber() {
        this.$store.dispatch('install/releaseNumber', this.selectedInstall)
          .then(res => {
            this.isOpenModalRelease = false
            this.loadData()
          })
          .catch(() => {
          })
      },
      toggleActive (record) {
        this.$store.dispatch('install/toggleActive', record)
          .then(res => {
          })
          .catch(() => {})
      },

      openShowHTMLCodeModal (install) {
        this.getEmbedCode(install)
      },
      closeModalCode() {
        this.isOpenModalCode = false
        this.selectedInstall = null
      },

      getEmbedCode (install) {
        this.embedCode = null

        this.$store.dispatch('install/getEmbedCode', install.id)
          .then(res => {
            this.selectedInstall = install
            this.embedCode = res.data.code
            this.isOpenModalCode = true
          })
          .catch(() => {})
      },

      copyEmbedCode () {
        this.selectedInstall = null

        document.getElementById('embedCode').select()
        document.execCommand('copy')

        Vue.$toast.open({
          message: 'Copied',
          type: 'success',
        })
      },
      ...mapMutations('install', {
        filterList: 'filterList'
      })
    },
    computed: {
      user () {
        return this.$store.state.auth.user
      },

      showInstallLimit() {
        return this.user.business.number_of_installs
      },
      
      tableData() {
        return this.$store.getters['install/tableData']('active');
      },

      tableTotal() {
        return this.$store.getters['install/tableTotal']('active');
      },

      agencyOwnerEmail () {
        return this.user.agency && this.user.agency.email
      },

      ...mapState('install', [
        'fetching',
        'didFetch',
        'processing',
        'page',
        'perPage',
      ]),
    }
  }
</script>

<style scoped lang="scss">
  .modal-title h5{
    width: 100%;
    color: #141414;
  }

  #embedCode {
    border: none;
    background-color: #dcdbe5;
    width: 100%;
    resize: none;
    padding: 10px;
    height: 110px;
  }
</style>
